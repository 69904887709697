import Alpine from 'alpinejs';
import Swiper from 'swiper';

import 'swiper/css';
import { EffectCreative } from 'swiper/modules';

Alpine.data('heartModule', () => ({
  swiper: null,
  swiperWrapper: null,

  init() {
    this.swiper = new Swiper('.heartModuleSwiper', {
      slidesPerView: 1.375,
      initialSlide: 0,
      spaceBetween: 32,
      centeredSlides: true,
      modules: [EffectCreative],
      breakpoints: {
        668: {
          slidesPerView: 2.5,
          spaceBetween: 40,
        },
      },
      effect: 'creative',
      creativeEffect: {
        next: {
          translate: ['110%', 10, 0],
          rotate: [0, 0, 5],
        },
        prev: {
          translate: ['-110%', 10, 0],
          rotate: [0, 0, -5],
        },
      },
    });
  },
}));
