// Alpine
import Alpine from 'alpinejs';

// Plugins
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import './plugins/axios';
import './plugins/gsap';
import './plugins/lodash';
import './plugins/siteimprove';
import './plugins/slide';

// Alpine Component
import './components/accordion';
import './components/audioController';
import './components/cardsAnimation';
import './components/desktop-nav';
import './components/entranceBlock';
import './components/header';
import './components/memberMeetingSignup';
import './components/heartModule';
import './components/mobile-nav';
import './components/modal';
import './components/search-form';
import './components/self-service';
import './components/splitWord';
import './components/stepByStep';
import './components/stickyObserver';
import './components/subsidy-symbol';
import './components/swiper';
import './components/tableCellWidth';
import './components/testimonials';
import './components/top-bar';
import './components/video-player';
import './components/videoController';

// Polyfills
import 'focus-visible'; // :focus-visible polyfill
import 'intersection-observer'; // intersection-observer polyfill
import Swiper from 'swiper';
import 'whatwg-fetch'; // window.fetch polyfill for IE11

declare global {
  interface Window {
    Alpine: typeof global;
    Swiper: any;
  }
}

window.Swiper = Swiper;
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.start();
