import Lottie from 'lottie-web';
import Alpine from 'alpinejs';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    intersectLottie();
  }
}

Alpine.data('entranceBlock', () => ({
  lottiePlayer: null,
  quoteText: null,
  intersectLottie() {
    this.lottiePlayer = this.$refs.lottiePlayer as HTMLDivElement;
    const entranceBlockContainer = this.$refs
      .entranceBlockContainer as HTMLQuoteElement;
    this.quoteText = entranceBlockContainer.children[0] as HTMLQuoteElement;

    const animation = Lottie.loadAnimation({
      container: this.lottiePlayer,
      loop: true,
      autoplay: true,
      renderer: 'svg',
      path: '/lottie/ungelinjen.json',
    });

    animation.addEventListener('drawnFrame', (e) => {
      if (this.quoteText === undefined) return;
      if (
        e.currentTime > 60 &&
        e.currentTime <= 300 &&
        !this.quoteText.classList.contains('lottie-quote-animation')
      ) {
        this.quoteText.classList.remove('animate-fade-out');
        setTimeout(() => {
          this.quoteText.classList.add('lottie-quote-animation');
          this.quoteText.classList.remove('opacity-0');
        }, 0);
      }
      if (
        e.currentTime > 300 &&
        !this.quoteText.classList.contains('animate-fade-out')
      ) {
        this.quoteText.classList.remove('lottie-quote-animation');
        setTimeout(() => {
          this.quoteText.classList.add('animate-fade-out');
          this.quoteText.classList.add('opacity-0');
        }, 0);
      }
    });
  },
}));
